// extracted by mini-css-extract-plugin
export var cyan = "accommodation-module--cyan--1pj0p";
export var cyanBg = "accommodation-module--cyan-bg--smivq";
export var cyanBo = "accommodation-module--cyan-bo--3reNc";
export var androidGreen = "accommodation-module--androidGreen--3cHwE";
export var androidGreenBg = "accommodation-module--androidGreen-bg--3hmot";
export var androidGreenBo = "accommodation-module--androidGreen-bo--1MLVO";
export var lavender = "accommodation-module--lavender--2IKXY";
export var lavenderBg = "accommodation-module--lavender-bg--2vYRd";
export var lavenderBo = "accommodation-module--lavender-bo--2C1gC";
export var corn = "accommodation-module--corn--1fYNr";
export var cornBg = "accommodation-module--corn-bg--t_VWg";
export var cornBo = "accommodation-module--corn-bo--3USXw";
export var trns = "accommodation-module--trns--ope-c";
export var section = "accommodation-module--section--3HPrK";
export var subSection = "accommodation-module--subSection--1VSyj";
export var bannedArea = "accommodation-module--bannedArea--1GSfB";