import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/pageTitle"

import { Container, Row, Col } from "react-bootstrap"

import { FaBan } from "react-icons/fa"

import * as styles from "../styles/components/accommodation.module.sass"
import { graphql } from "gatsby"
import Img from "gatsby-image"

export const query = graphql`
  query {
    allFile(filter: {sourceInstanceName: {eq: "accommodationImages"}}) {
      edges {
        node {
          name
          childImageSharp {
          fluid(maxWidth: 1200, maxHeight: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    }
  }
`

/************************************ 
    Data
*************************************/
const introductoryText = "The windmill is mainly geared to adult couples, with three cosy rooms and a bathroom. The full central heating will keep you as snug as you like. The furnishings have been adapted to the round layout. There are maple floors and a good deal of oak and teak. Please note that the winding stairs are not very suitable for small children or people with mobility problems."

const downstairs = {
  subheading: "Downstairs",
  info: "The main kitchen/dining/living room with cooking facilities (double oven/grill, two-ring halogen hob, microwave), dining table for four, and leather sofa. Open the outer door for magnificent views over towards Dartmoor.",
  pics: ["kitchen1", "kitchen2", "leatherSofa"]
}

const firstFloor = {
  subheading: "First Floor",
  info: "Double bedroom with a Victorian bed, with double-sprung mattress and goosedown duvet. Two windows offer views to Dartmoor to the west and towards Torbay to the east.",
  pics: ["bedroom1"]
}

const secondFloor = {
  subheading: "Second Floor",
  info: "The large bathroom has an oval bath with shower over, wooden floor and views over the field to the north.",
  pics: ["bathroom1", "bathroom2"]
}

const eye = {
  subheading: "Viewing Loft",
  info: "The viewing loft or 'Miller's Eye' is reached by a space-saver staircase, and has splendid all-round views towards Torbay, the sea, the South Hams and Dartmoor. Borrow our binoculars to spy on 400 square miles of countryside and the local local farming and wild life. There is a small television with Freeview and DVD player.",
  pics: ["eye1", "eye2"]
}

const outside = {
  info: "The mill is about 50 metres off a country lane. Part of the original cobbled level beside it is now arranged for seating and there is also a grassed area. Enjoy a drink or a meal outside while taking in the magnificent views. The ground abuts directly onto the surrounding five-acre field which is actively farmed. At the field gate from Moles Lane there is a sunken car-parking area. At some times of the year, there is room for a tent by special arrangement.",
  pics: ["outside1", "outside2"]
}

/************************************ 
    Picture Section
*************************************/

const Pictures = ({ info, edges }) => {
  const relevantEdges = edges.filter(edge => info.pics.indexOf(edge.node.name) > -1);
  const pictures = relevantEdges.map(edge => (<Col sm="12" md="6" className="text-center py-2"><Img fluid={edge.node.childImageSharp.fluid} alt="cat" /></Col>))
  const headerComponent = (<Row>
    <Col className="text-center">
      <h4>{info.subheading}</h4>
    </Col>
  </Row>);

  const infoComponent = (
    <Row>
      <Col>
        <p>{info.info}</p>
      </Col>
    </Row>
  );

  return (
    <Container className={styles.subSection}>
      {info.subheading && headerComponent}
      {info.info && infoComponent}

      <Row className="justify-content-around">
        {pictures}
      </Row>
    </Container>
  )
};

/************************************ 
 * Inside Section
*************************************/
const Inside = ({edges}) => (
  <Container fluid className={styles.section}>
    <Row>
      <Col >
        <h1>Inside</h1>
        <p >{introductoryText}</p>
      </Col>
    </Row>
    <Row className="justify-content-md-center">
      <Col sm="auto">
        <p className={styles.bannedArea}><FaBan /> No smoking and no pets please, due to fire insurance and farmer's rules.</p>
      </Col>
    </Row>
    <Pictures info={downstairs} edges={edges} />
    <Pictures info={firstFloor} edges={edges} />
    <Pictures info={secondFloor} edges={edges} />
    <Pictures info={eye} edges={edges} />
  </Container>
)
/************************************ 
 * Outside Section
*************************************/
const Outside = ({edges}) => (
  <Container fluid className={styles.section}>
    <Row>
      <Col >
        <h1>Outside</h1>
      </Col>
    </Row>
    <Pictures info={outside} edges={edges} />
  </Container>
)
/************************************ 
 * Page
*************************************/
const AccommodationPage = ({data}) => (
  <Layout pageInfo={{ pageName: "Accommodation" }}>
    <SEO title="Accommodation" />
    <PageTitle title="Accommodation" />
    <Inside edges={data.allFile.edges}/>
    <Outside edges={data.allFile.edges}/>
  </Layout>
)

export default AccommodationPage
